import { faPlus, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inviteUsers, inviteUsersSuppliers } from 'api/services/InviteService';
import { Countries } from 'api/types/countries';
import { UserContext } from 'context/User';
import { hasRole } from 'helpers/Roles';
import { useContext, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { FetchResponse, Option } from '../../../types/global';
import CompanySelector from '../../pages/products/CompanySelector';
import { toast } from 'react-toastify';

interface InviteModalProps {
  show: boolean;
  onHide: Function;
}

export interface InviteUserInterface {
  users: {
    firstname: string;
    lastname: string;
    email: string;
    country_code?: string;
    editor: boolean;
  }[];
}

export const InviteModal = ({ show, onHide }: InviteModalProps) => {
  const [companyKey, setCompanyKey] = useState();
  const user = useContext(UserContext);
  const EventSalesUser = hasRole(['event-sales'], user);

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      users: [
        {
          firstname: '',
          lastname: '',
          email: '',
          country_code: '',
          editor: false
        }
      ]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users'
  });
  const handleSave = (values: InviteUserInterface) => {
    companyKey && EventSalesUser
    ? inviteUsersSuppliers(companyKey, values, (res: FetchResponse) => {
        onHide();
      })
    :
    companyKey &&
      inviteUsers(
        companyKey,
        values,
        (res: FetchResponse) => {
          onHide();
        },
        (error: FetchResponse) => {
          const { data: errorData } = error?.response;
          if (
            errorData?.status === 400 &&
            errorData?.additional_data?.failures?.some((failure: string) =>
              failure.includes('already exists')
            )
          )
            errorData?.additional_data?.failures?.forEach((failure: string) => {
              toast.error(failure.replace('User', 'An account with email '));
            });
          else {
            toast.error('Something went wrong');
          }
        }
      );
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="fixed-height-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center">
          {EventSalesUser ? 'Invite suppliers' : ' Invite your colleagues'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          scrollBehavior: 'smooth',
          height: 'fit-content',
          maxHeight: '70vh',
          overflowY: 'scroll'
        }}
      >
        <div className="w-30 required">
          <Form.Label>Company</Form.Label>
          <CompanySelector
            onSelect={(option: Option) => setCompanyKey(option.value)}
            defaultValue={undefined}
            isLoading={undefined}
            style={undefined}
            page={undefined}
          />
        </div>
        <Form
          onSubmit={handleSubmit(handleSave)}
          className="form"
          id="inviteForm"
        >
          {fields.map((item, index) => {
            return (
              <>
                <div
                  className="d-flex  gap-2 align-items-center my-4"
                  style={{
                    padding:
                      index !== 0 || fields.length > 1
                        ? '0 0 1em 1em'
                        : '0 1em 1em 1em',
                    boxShadow: '0 0 3px 0px #d9d9d9',
                    borderRadius: '4px'
                  }}
                  key={item.id}
                >
                  <Row key={index}>
                    <Col lg={6}>
                      <Form.Group className="form-group required">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={'First name'}
                          {...register(`users.${index}.firstname`, {
                            required: true
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group required">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={'Last name'}
                          {...register(`users.${index}.lastname`, {
                            required: true
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={8}>
                      <Form.Group className="form-group required">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={'Email'}
                          {...register(`users.${index}.email`, {
                            required: true
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="form-group">
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          {...register(`users.${index}.country_code`)}
                          placeholder="Country"
                        >
                          <option disabled value="">
                            Country
                          </option>
                          {Countries.map(country => (
                            <option value={country.value} key={country.label}>
                              {country.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Check>
                          <Form.Check.Input
                            {...register(`users.${index}.editor`)}
                          />
                          <Form.Check.Label style={{ lineHeight: 'unset' }}>
                            Select Editor permissions if you would like your
                            colleague to help with reviewing or amending your
                            product data.
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Row>

                  <div>
                    {(index !== 0 || fields.length > 1) && (
                      <button
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          className="text-danger"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </Form>
      </Modal.Body>
      <div className="modified-modal-footer">
        <div className="d-inline">
          <Button
            variant="falcon-default"
            type="button"
            onClick={() => {
              append({
                firstname: '',
                lastname: '',
                email: '',
                country_code: '',
                editor: false
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> users
          </Button>
        </div>
        <div className="right">
          <Button
            children={'Invite'}
            type="submit"
            form={`inviteForm`}
            disabled={!companyKey || !isDirty}
          />
        </div>
      </div>
    </Modal>
  );
};
