import {
  faChevronDown,
  faChevronUp,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import { Roles } from 'helpers/Roles';
import { useContext, useEffect, useState } from 'react';
import { Collapse, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { InviteModal } from './InviteModal';

const NavbarTop = () => {
  const { t } = useTranslation();
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');
  const [inviteModal, setInviteModal] = useState();
  const [showDropShadow, setShowDropShadow] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 748);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const inCosmeticsList = (
    <ul>
      <li>
        <a
          href="https://www.in-cosmetics.com/"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          in-cosmetics Group
        </a>
      </li>
      <li>
        <a
          href="https://www.in-cosmetics.com/global/en-gb.html"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Global
        </a>
      </li>
      <li>
        <a
          href="https://www.in-cosmetics.com/asia/en-gb.html"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Asia
        </a>
      </li>
      <li>
        <a
          href="https://www.in-cosmetics.com/korea/en-gb.html"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Korea
        </a>
      </li>
      <li>
        <a
          href="https://www.in-cosmetics.com/latin-america/en-gb.html"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Latin America
        </a>
      </li>
      <li>
        <a
          href="https://connect.in-cosmetics.com/"
          className="global-nav-link"
          target="_blank"
          rel="noreferrer"
        >
          Connect
        </a>
      </li>
      {/* <li>
  <Link to="/discover" className="global-nav-link">
    Discover
  </Link>
</li> */}
    </ul>
  );
  return (
    <>
      <Navbar
        className={classNames('reed-navbar fs--1 navbar-top sticky-kit', {
          // 'navbar-glass-shadow': showDropShadow
          'navbar-glass-shadow': showDropShadow && !isChat
        })}
        expand={
          navbarPosition === 'top' || navbarPosition === 'combo'
            ? topNavbarBreakpoint
            : true
        }
      >
        <div className="global-nav-base">
          <nav
            className="global-nav reed-max-width"
            onClick={() => isMobile && setOpen(!open)}
          >
            {isMobile && (
              <>
                <div className="mobile-expandable-navbar">
                  {t('nav.expand', { expandStatus: open ? 'Close' : 'Expand' })}
                  <FontAwesomeIcon
                    icon={open ? faChevronUp : faChevronDown}
                    className="ps-2"
                    style={{ color: 'white' }}
                  />
                </div>
                <Collapse in={open}>{inCosmeticsList}</Collapse>
              </>
            )}
            {!isMobile && inCosmeticsList}
          </nav>
        </div>
        <div className="navigation-reed-top-section align-items-center my-3 text-white justify-content-between reed-max-width px-3">
          <div className="d-flex gap-3">
            {/* <button className="btn btn-main btn-favorite">
            <FontAwesomeIcon icon={faHeart} className="favorite-icon" />
          </button>*/}
            <Roles
              validRoles={[
                'supplier-manager',
                'event-sales',
                'supplier-editor'
              ]}
            >
              <>
                <button
                  className="btn btn-main me-5"
                  onClick={() => setInviteModal(true)}
                >
                  {' '}
                  <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Invite
                </button>
                {inviteModal && (
                  <InviteModal show={inviteModal} onHide={setInviteModal} />
                )}
              </>
            </Roles>
          </div>
        </div>
        <div className="navbar-reed-navigation   border-top border-off-white">
          <div className="d-flex banner-layout-container navigation-responsive">
            <Navbar.Toggle
              className={classNames('toggle-icon-wrapper me-md-3 me-2', {
                'd-lg-none': navbarPosition === 'top',
                [`d-${navbarBreakPoint}-none`]:
                  navbarPosition === 'vertical' || navbarPosition === 'combo'
              })}
              as="div"
            >
              <button
                className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                onClick={handleBurgerMenu}
                id="burgerMenu"
              >
                <span className="navbar-toggle-icon">
                  <span className="toggle-line" />
                </span>
              </button>
            </Navbar.Toggle>
            {/* )} */}

            {/* <Logo at="navbar-top" width={135} id="topLogo" /> */}
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default NavbarTop;

export const inCosmeticsList = (
  <ul>
    <li>
      <a
        href="https://www.in-cosmetics.com/"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        in-cosmetics Group
      </a>
    </li>
    <li>
      <a
        href="https://www.in-cosmetics.com/global/en-gb.html"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Global
      </a>
    </li>
    <li>
      <a
        href="https://www.in-cosmetics.com/asia/en-gb.html"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Asia
      </a>
    </li>
    <li>
      <a
        href="https://www.in-cosmetics.com/korea/en-gb.html"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Korea
      </a>
    </li>
    <li>
      <a
        href="https://www.in-cosmetics.com/latin-america/en-gb.html"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Latin America
      </a>
    </li>
    <li>
      <a
        href="https://connect.in-cosmetics.com/"
        className="global-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Connect
      </a>
    </li>
    {/* <li>
<Link to="/discover" className="global-nav-link">
  Discover
</Link>
</li> */}
  </ul>
);
