import { createContext } from 'react';

const UserContext = createContext();

const UserProvider = ({ children, value }) => {
  const state = value;
  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
