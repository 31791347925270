import axios from 'axios';
import { toast } from 'react-toastify';
import keycloak from '../auth/Keycloak';

export const requestUnauthenticated = (options, success, handleError) => {
  axios
    .request(options)
    .then(function (response) {
      success(response);
    })
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};

export const request = (options, success, handleError, toastOptions,customErrorMessage=false) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  if (keycloak.token) {
    options['headers']['Authorization'] = `Bearer ${keycloak.token}`;
  }
  let toastId;
  let promise = () =>
    new Promise((resolve, reject) =>
      axios.request(options).then(
        function (response) {
          success(response);
          resolve();
        },
        function (error) {
          if (toastOptions && !toastOptions?.error && !customErrorMessage) {
            toast.dismiss(toastId);
            toast.error(error.response.data.detail||error.message);
          }
          if (handleError) {
handleError(error);
          }
          reject();
        }
      )
    );
  if (toastOptions) {
    toastId = toast.promise(promise, toastOptions);
  } else {
    promise();
  }
};

export const requestRedirectDownload = (options, handleError) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  options['headers']['Authorization'] = `Bearer ${keycloak.token}`;
  const headers = options.headers;
  fetch(options.url, { headers })
    .then(res => window.open(res.url, '_blank'))
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};
