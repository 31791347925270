import AsyncSelect from 'react-select/async';
import { useContext, useEffect, useState } from 'react';
import {
  findCompanyOptions,
  findCompanyOptionsForEvent
} from '../../../api/services/CompaniesService';
import { hasRole } from 'helpers/Roles';
import { UserContext } from 'context/User';
import { useTranslation } from 'react-i18next';

const companyFilterStyle = {
  container: provided => ({
    ...provided,
    minWidth: 230
  })
};

const CompanySelector = ({
  onSelect,
  defaultValue,
  isLoading,
  style,
  page,
  ...props
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const user = useContext(UserContext);
  const handleChange = selectedOption => {
    setSelected(selectedOption);
    onSelect && onSelect(selectedOption ? selectedOption : '');
  };
  const EventSales = hasRole(['event-sales'], user);
  const loadCompanies = (inputValue, callback) => {
    if (EventSales) {
      findCompanyOptionsForEvent(inputValue, response => {
        const companyOptions = [];
        response.data.content.forEach(item => {
          companyOptions.push({
            value: item.key,
            label: item.name
          });
        });
        setOptions(companyOptions);

        if (inputValue.length > 0) {
          callback(options);
        }
      });
    } else {
      findCompanyOptions(inputValue, response => {
        const options = [];
        response.data.content.forEach(item => {
          options.push({
            value: item.key,
            label: item.name
          });
        });
        if (options.length === 1) {
          handleChange(options[0]);
        }
        setOptions(options);
        if (inputValue.length > 0) {
          callback(options);
        }

        isLoading?.setIsLoadingCompanies(false);
      });
    }
  };

  useEffect(() => {
    if (EventSales) {
      findCompanyOptionsForEvent('', response => {
        const options = [];
        response.data.content.forEach(item => {
          options.push({
            value: item.key,
            label: item.name
          });
        });
        if (options.length === 1) {
          handleChange(options[0]);
        }
        setOptions(options);
        options?.length > 1 && isLoading?.setIsLoadingCompanies(false);
      });
    } else {
      findCompanyOptions('', response => {
        const options = [];
        response.data.content.forEach(item => {
          options.push({
            value: item.key,
            label: item.name
          });
        });
        if (options.length === 1) {
          handleChange(options[0]);
        }

        setOptions(options);
        options?.length > 1 && isLoading?.setIsLoadingCompanies(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!selected) {
      if (defaultValue !== undefined) {
        setSelected(defaultValue);
      }
    }
  }, [defaultValue]);
  return (
    <AsyncSelect
      inputId="company-selector"
      classNamePrefix={'react-select'}
      isClearable
      value={selected}
      onChange={handleChange}
      loadOptions={loadCompanies}
      defaultOptions={options}
      defaultValue={defaultValue}
      cacheOptions
      styles={companyFilterStyle}
      placeholder={t('SelectPlaceholder', { placeholder: 'a company' })}
      {...props}
    />
  );
};

export default CompanySelector;
