import NavbarTop from 'components/navbar/top/NavbarTop';
import is from 'is_js';
import { Suspense, lazy, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const DiscoverPage = lazy(() =>
  import('components/pages/Discover/DiscoverPage')
);

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  //Default pages

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Suspense fallback={<NavbarTop />}>
          <Routes>
            <Route path="*" element={<DiscoverPage />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default Layout;
