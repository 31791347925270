import { UserContext } from 'context/User';
import PropTypes from 'prop-types';
import { useContext } from 'react';




export const Roles = ({ validRoles, children, excludeRoles }) => {
  const user = useContext(UserContext);
  // user?.roles.push('event-sales');
  let allowUser = hasAccess(validRoles, excludeRoles, user);
  if (!allowUser) return null;

  return <>{children}</>;
};
Roles.propTypes = {
  children: PropTypes.element
};

export const hasAccess = (validRoles, excludeRoles, user) => {
  let allowUser = false;

  if (user) {
    if (isSupport(user)) {
      allowUser = true;
    } else {
      validRoles.forEach(role => {
        if (user.roles.includes(role)) {
          allowUser = true;
        }
      });

      if (excludeRoles) {
        excludeRoles.forEach(role => {
          if (user.roles.includes(role)) {
            allowUser = false;
          }
        });
      }
    }
  }
  return allowUser;
};

export const isSupport = user => {
  return hasRole(allRoles.SUPPORT_ROLES, user);
};

export const isSupplier = user => {
  return hasRole(allRoles.SUPPLIER_ROLES, user);
};

//Can edit verified contacts
export const isBuyer = user => {
  return !hasRole(allRoles.ADMIN_ACCESS_ROLES, user);
};

export const hasAdminRoles = user => {
  return hasRole(allRoles.ADMIN_ACCESS_ROLES, user);
};

//Can edit verified contacts
export const canEditVerified = () => {
  const user = useContext(UserContext);
  const authorizedRoles = ['chemberry-support', 'chemberry-editor'];
  return hasRole(authorizedRoles, user);
};

export const hasRole = (validRoles, user) => {
  let allowUser = false;
  if (user && validRoles) {
    validRoles.forEach(role => {
      if (user.roles.includes(role)) {
        allowUser = true;
      }
    });
  }
  return allowUser;
};

export const getUserHasFeature = (user, currentCompany, feature) => {
  if (isSupport(user)) return true;
  let companyFeatures = user?.companies?.find(
    company => company.key === currentCompany
  );
  let hasFeature = companyFeatures?.features?.[feature] ?? false;
  return hasFeature;
};

const allRoles = {
  ALL_AND_EXT: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'buyer',
    'buyer-manager',
    'event-admin'
  ],
  ALL: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'buyer',
    'buyer-manager'
    // 'event-admin'
  ],
  EXTERNAL: ['event-admin'],
  VIEW_COMPANY: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  CREATE_COMPANY: [
    // 'buyer-manager',
    'supplier-manager',
    'chemberry-support',
    'chemberry-editor'
  ],
  EDIT_COMPANY: [
    // 'buyer-manager',
    'supplier-manager',
    'supplier-editor',
    'chemberry-support',
    'chemberry-editor'
  ],

  CREATE_PRODUCTS: ['supplier-manager', 'supplier-editor', 'chemberry-support'],
  PRODUCTS: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  EDIT_PRODUCTS: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor'
  ],

  PROJECT_SPACE: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  CREATE_PROJECT: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  EDIT_PROJECT: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  SUPPLIER_ROLES: [
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  BUYER_ROLES: ['buyer', 'buyer-manager'],
  ADMIN_ACCESS_ROLES: [
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin'
    // 'event-admin'
  ],
  EVENT_ADMIN: ['event-admin'],
  SUPPORT_ROLES: ['chemberry-support', 'chemberry-editor', 'chemberry-admin'],
  MANAGER_ROLES: ['supplier-manager', 'buyer-manager'],
  EDIT_QUOTE: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor'
  ]
};

Object.freeze(allRoles);

export default allRoles;
