import 'helpers/initFA';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './i18n';
import Main from './Main';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_API_MOCKING === 'enabled'
) {
  const { worker } = await import('./mocks/browser');
  worker.start();
}

const root = createRoot(document.getElementById('main'));
root.render(
  <I18nextProvider i18n={i18n}>
    <Main>
      <App />
    </Main>
  </I18nextProvider>
);
