import { request } from '../api';
import { InviteUserInterface } from 'components/navbar/top/InviteModal';

export const inviteUsers = (
  companyKey: string,
  data: InviteUserInterface,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const baseApi = process.env.REACT_APP_COVALO_API;
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/events/${process.env.REACT_APP_COVALO_WHITELABEL_EVENT_KEY}/users/invite`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  const isMultiple = data?.users?.length > 1;
  const toastOptions = {
    pending: `Sending invite${isMultiple ? 's' : ''}`,
    success: `Invite${isMultiple ? 's' : ''} sent`
  };
  request(options, handleSuccess, handleError, toastOptions,true);
};

export const inviteUsersSuppliers = (
  companyKey: string,
  data: InviteUserInterface,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const baseApi = process.env.REACT_APP_COVALO_API;
  const options = {
    method: 'POST',
    url: `${baseApi}/events/${process.env.REACT_APP_COVALO_WHITELABEL_EVENT_KEY}/companies/${companyKey}/users/invite`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  const isMultiple = data?.users?.length > 1;
  const toastOptions = {
    pending: `Sending invite${isMultiple ? 's' : ''}`,
    success: `Invite${isMultiple ? 's' : ''} sent`
  };
  request(options, handleSuccess, handleError, toastOptions);
};
